.email-temp {
  /* height: 76px; */
  height: 76px;
  /* background-image: url(https://xoxoengage-images.s3.ap-southeast-1.amazonaws.com/store/template/default/Birthday-1.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 8px;
}

.explore-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 186px;
  height: 73px;
  min-height: 73px;
  border: 1px dashed rgb(217, 217, 218);
  border-radius: 8px;
  flex: 1 0 auto;
  order: 0;
  align-self: stretch;
  -webkit-box-flex: 1;
}

/* For screens with a maximum width of 576px */
@media (max-width: 576px) {
  .col-sm-12 {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
    margin-bottom: 1rem;
  }

  .explore-button {
    width: 100%;
    text-align: center;
  }
}

/* For screens with a width between 577px and 991px */
@media (min-width: 577px) and (max-width: 991px) {
  .col-sm-12 {
    flex-wrap: wrap;
  }

  .card {
    flex-basis: calc(50% - 1rem);
    margin-bottom: 1rem;
  }

  .explore-button {
    width: 100%;
    text-align: center;
  }
}

.temp-img {
  max-width: 100%;
  height: -webkit-fill-available;
  display: block;
}

.email-preview .company-upload-logo {
  border: none;
  border-radius: 0;
}

.email-preview .company-upload-logo.card-img {
  width: 100%;
}

.email-preview .company-upload-logo.card-img .edit--icon {
  top: 3rem;
}

.email-preview .note {
  position: relative;
  bottom: 4rem;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
}

.email-preview .note.logo-icon {
  font-size: 12px;
  line-height: 15px;
  bottom: 20px;
}

.email-preview .email-title {
  font-size: 22px;
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 8px;
  text-align: center;
}

.email-preview .email-body p {
  text-align: left;
  color: #1a1a1a;
  font-size: 16px;
}

.email-preview .email-table {
  border: 1px solid #f5f5f5;
  font-size: 14px;
  color: #333;
}

.email-preview .email-message {
  background-color: #f2feff;
}

.email-preview .email-message .fa-pencil-alt {
  float: right;
  padding: 10px;
  cursor: pointer;
}

.email-preview .email-message .form-control,
.email-preview
  .email-message
  .ReactTable.customtable
  .rt-table
  .rt-thead.-filters
  input[type="search"],
.ReactTable.customtable
  .rt-table
  .rt-thead.-filters
  .email-preview
  .email-message
  input[type="search"] {
  background-color: #f2feff;
  border: transparent;
}

.email-preview .email-message .form-control:focus,
.email-preview
  .email-message
  .ReactTable.customtable
  .rt-table
  .rt-thead.-filters
  input:focus[type="search"],
.ReactTable.customtable
  .rt-table
  .rt-thead.-filters
  .email-preview
  .email-message
  input:focus[type="search"] {
  background-color: #f2feff;
}

.email-preview .email-message .editmsg {
  float: right;
  position: relative;
  top: -2.5rem;
  right: 1rem;
  cursor: pointer;
}

.email-preview .email-message .editclose {
  float: right;
  position: relative;
  top: -1.5rem;
  right: 10px;
  cursor: pointer;
}

.email-preview .new-email-subject {
  min-height: 36px;
  color: #5f738c;
}

.email-preview .new-email-subject.errInput {
  border: 1px solid #d21c1c;
  background-color: #faeaea;
}

.email-preview .email-subject {
  min-height: 48px;
  font-size: 16px;
  font-weight: 400;
  color: #120101;
}

.email-preview .subject-title {
  color: #3c3c3c;
}

.email-preview .subject-title span {
  color: #707070;
  font-size: 12px;
}

.email-preview .mail-sub {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.email-preview .mail-sub .editmsg {
  float: right;
  position: relative;
  top: -1.8rem;
  right: 5px;
  cursor: pointer;
}

.email-preview .mail-sub .editclose {
  float: right;
  position: relative;
  top: -1.6rem;
  right: 10px;
  cursor: pointer;
}

.email-preview .m_logo {
  margin: 0.5rem 0 0 1rem;
}

.email-preview .heading--border {
  padding: 5px;
  border-radius: 4px 4px 0 0;
  background-color: #fdaf74;
}

.email-preview .mail-message {
  padding: 2rem 4rem;
  text-align: center;
  font-size: 18px;
  background-color: #fff9f5;
}

.email-preview .code-box {
  padding: 2rem;
  border-radius: 8px;
  margin-bottom: 2rem;
}

.email-preview .plum {
  font-size: 14px;
  color: #c7417b;
}

.email-preview .e-heading {
  color: #505050;
  font-size: 16px;
  font-weight: 500;
}

.email-preview .border1 {
  box-shadow: 0 0 4px 2px #e0e0e0;
}

.email-preview .emailTempBckground {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eff2f5;
}

.email-preview .emailTempBckground .previewMode {
  padding-bottom: 40px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}

.email-preview .emailTempBckground .previewMode .previewOptions {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.email-preview .emailTempBckground .previewMode .previewOptions .systemLayout {
  padding: 8px 12px;
  color: #5f738c;
  background-color: #f5f7f9;
  font-weight: 500;
}

.email-preview
  .emailTempBckground
  .previewMode
  .previewOptions
  .systemLayout:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.email-preview
  .emailTempBckground
  .previewMode
  .previewOptions
  .systemLayout:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.email-preview
  .emailTempBckground
  .previewMode
  .previewOptions
  .systemLayout:hover {
  background-color: #f5f7f9;
  color: #252a31;
}

.email-preview
  .emailTempBckground
  .previewMode
  .previewOptions
  .systemLayout:hover
  svg
  path {
  fill: #252a31;
}

.email-preview
  .emailTempBckground
  .previewMode
  .previewOptions
  .systemLayout.active {
  background-color: #e8edf1;
  color: #252a31;
}

.email-preview .emailTemplateSection {
  max-width: 600px;
}

.email-preview .emailTemplateSection .logoSection {
  padding: 20px;
}

.email-preview .emailTemplateSection .logoSection .showMask {
  display: none !important;
}

.email-preview .emailTemplateSection .logoSection:hover .showMask {
  display: unset !important;
}

.email-preview .emailTemplateSection .mailBannerImage .showMask {
  display: none !important;
}

.email-preview .emailTemplateSection .mailBannerImage:hover .showMask {
  display: unset !important;
}

.email-preview .emailTemplateSection .showMask {
  position: absolute;
  background-color: #252a31;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.email-preview .emailTemplateSection .showMask .editBtn {
  position: relative;
  top: 24px;
}

.email-preview .emailTemplateSection .emailBody span {
  line-height: 24px;
}

.email-preview .emailTemplateSection .emailBody .overlayBanner {
  display: none;
  position: absolute;
  background-color: #252a31;
  width: 100%;
  opacity: 0.9;
  left: 0;
  top: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  font-size: 12px;
}

.email-preview
  .emailTemplateSection
  .emailBody
  .codeDistributionEmailBody:hover
  .overlayBanner,
.email-preview
  .emailTemplateSection
  .emailBody
  .linkDistributionEmailBody:hover
  .overlayBanner,
.email-preview
  .emailTemplateSection
  .emailBody
  .plumProDistributionEmailBody:hover
  .overlayBanner,
.email-preview
  .emailTemplateSection
  .emailBody
  .pointsDistributionEmailBody:hover
  .overlayBanner {
  display: flex;
}

.email-preview .emailTemplateSection .emailBody .showMask {
  display: none !important;
}

.email-preview .emailTemplateSection .emailBody:hover .showMask {
  display: unset !important;
}

.email-preview .emailTemplateSection .rewardData {
  padding: 0px 0px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .08); */
  border-radius: 10px;
  /* background: url(https://res.cloudinary.com/dyyjph6kx/image/upload/v1638344263/store/email-bg-img.png); */
  background-position-x: center;
}

.email-preview .emailTemplateSection .rewardData .rewardAmount {
  color: #f15c22;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.email-preview .emailTemplateSection .rewardData .reward-title,
.email-preview .emailTemplateSection .rewardData .validity {
  line-height: 20px;
}

.email-preview .emailTemplateSection .rewardData .codeSection {
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.email-preview .emailTemplateSection .rewardData .codeSection .rewardValue {
  color: #f15c22;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.email-preview .emailTemplateSection .rewardData .codeSection .maskedCode {
  border: 1px solid #bac7d5;
  border-radius: 4px;
  padding: 8px;
  font-weight: 600;
  color: #252a31;
}

.email-preview
  .emailTemplateSection
  .rewardData
  .codeSection
  .maskedCode.mobile {
  width: 75%;
  margin: 0 auto;
}

.email-preview .emailTemplateSection .rewardBtnSection {
  padding-top: 12px;
  padding-bottom: 32px;
}

.email-preview .emailTemplateSection .rewardBtnSection .btnDiv {
  padding-top: 20px;
  padding-bottom: 20px;
}

.email-preview .emailTemplateSection .rewardBtnSection .btnDiv .showMask {
  display: none;
}

.email-preview .emailTemplateSection .rewardBtnSection .btnDiv:hover .showMask {
  display: unset;
}

.email-preview .emailTemplateSection .rewardBtnSection .btnDiv .redeemBtn {
  padding: 8px;
  background-color: #252a31;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  line-height: 24px;
  margin: 0 auto;
  max-width: 75%;
  text-align: center;
}

.email-preview .emailTemplateSection .rewardEmailFooter {
  padding: 24px 60px;
  background-color: #f5f7f9;
}

.email-preview .emailTemplateSection .rewardEmailFooter.mobile {
  padding: 24px 40px;
}

.email-preview .emailTemplateSection .rewardEmailFooter .content {
  color: #5f738c;
  font-size: 12px;
  line-height: 24px;
}

.email-preview .emailTemplateSection .rewardEmailFooter .content a {
  color: unset;
}

.email-preview .emailTemplateSection .rewardEmailFooter .content a.underline {
  text-decoration: underline !important;
}

.email-preview .emailTemplateSection.mobile {
  max-width: 375px;
}

.email-preview .emailTemplateSection.mobile .voucher-image-preview,
.email-preview .emailTemplateSection.mobile .voucher-template-image {
  height: 188px !important;
}

.email-preview .fontWeight {
  font-weight: 500;
}

.email-preview .fontWeight img {
  font-size: 16px;
}

.email-preview .star {
  color: #505050;
  font-size: 23px;
}

.email-preview .btn-preview {
  border: 2px solid #f9c33d;
  border-radius: 4px;
  color: #505050;
}

.email-preview .btn-preview,
.email-preview .btn-preview-pink {
  display: inline-block;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.email-preview .btn-preview-pink {
  border: 2px solid #c7417b;
  background-color: #c7417b;
  border-radius: 4px;
  color: #fff !important;
}

.email-preview .point-header {
  font-size: 16px;
  color: #505050;
}

.logoPreview .logo-template-image {
  height: 32px;
  max-height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* For screens up to 576px width */
@media (max-width: 576px) {
  .codeSection {
    flex-direction: column;
  }
  .codeSection > div {
    margin-bottom: 10px;
  }
}

/* For screens between 577px and 768px width */
@media (min-width: 577px) and (max-width: 768px) {
  .rewardData {
    padding: 10px;
  }
}

/* For screens above 768px width */
@media (min-width: 769px) {
  .codeSection {
    flex-direction: row;
  }
  .codeSection > div {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.izGpuT img {
  height: 40px;
  object-fit: cover;
}

.otpContainer {
  margin: 2% auto;
}

.otpInput {
  width: 2rem !important;
  height: 2rem;
  margin: 0 0.2rem;
  font-size: 1rem;
  text-align: center;
  border-top: 1px solid #424242;
  border-left: 1px solid #424242;
  border-right: 1px solid #424242;
  border-bottom: 1px solid #424242; /* Set the color and style you desire */
  border-radius: 0;
  box-shadow: none;
  border-radius: 6px;
  outline: none;
  position: relative;
  z-index: 1;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 375px) {
  .otpInput {
    width: 1.5rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* This removes the margin that's applied to the buttons */
}

.allsection {
  height: calc(100vh - 85px);
  overflow-y: scroll;
}
.fixed-margin {
  margin-bottom: 5rem !important;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-text {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
}

.input-group-prepend .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}

.input-group-append .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}
.our-reliable-and {
  top: 70px;
  font-size: 16px;
  line-height: 30px;
  width: 100%;
}

.send-rewards1 {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: white;
  text-align: left;
}
.send-rewards-text {
  cursor: pointer;
  border: 0;
  left: 22px;
  padding: 6px, 7px;
  background-color: #594df1;
  top: 158px;
  border-radius: 14px;
  width: 171px;
  height: 51px;

  left: 22px;
  display: flex;
  align-items: center;
  z-index: 2;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}
.add-fund-icon-group:hover,
.send-rewards-text:hover,
.new-bt:hover {
  background-color: #594df1;
  color: white;
}
.add-fund-icon-group {
  background-color: #594df1;
  border: none;
}
.icon {
  position: absolute;
  top: 58.5px;
  right: 2.5rem;
  width: 44.2px;
  height: 44.2px;
  z-index: 2;
}
.input-bottom-line {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #424242; /* Set the color and style you desire */
  border-radius: 0;
  box-shadow: none;
  /* Additional styles if needed */
}
.input-bottom-line:hover,
.input-bottom-line:focus {
  box-shadow: none; /* Remove box shadow on hover and focus */
}

.new-bt {
  cursor: pointer;
  border: none;
  left: 22px;
  padding: 6px, 7px;
  background-color: var(--color-crimson-200);
  color: white;
  border-radius: 25px;
  width: 171px;
  height: 51px;
  display: flex;
  align-items: center;
  z-index: 2;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
}

.btn-otp {
  background-color: #dcdcdc;
  border-radius: 2rem;
  color: black;
  font-size: 15px;
}
.link-bt {
  color: rgb(89, 77, 241);
}
.link-bt:hover {
  color: rgb(89, 77, 241);
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  background-color: var(--color-crimson-200);
}
h2#swal2-title {
  font-size: 0.987rem;
}
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.custom-file {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.custom-file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
}

.custom-file-label {
  position: relative;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
}

.dashed-border {
  border: 1px dashed gray;
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
  color: black;
}
.MuiDataGrid-cell .MuiDataGrid-cell--textLeft .MuiDataGrid-withBorderColor {
  width: 100%;
}

.input-border-right {
  border-right: none; /* Set the color and style you desire */

  box-shadow: none;
  /* Additional styles if needed */
}
.input-border-left {
  border-left: none; /* Set the color and style you desire */

  box-shadow: none;
  /* Additional styles if needed */
}

select {
  z-index: 1; /* or any value higher than other elements */
}
.select-show {
  position: relative;
  z-index: 0;
}
.custom-z-index {
  z-index: 1000; /* Your desired z-index value */
}
button.swal2-confirm.swal2-styled {
  background-color: #594df1;
  border-color: #594df1;
  border: none;
}
.bg-crimason {
  background-color: var(--color-crimson-100);
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 3rem;
}
.support-bt {
  cursor: pointer;
  border: none;
  left: 22px;
  padding: 10px, 10px;

  border-radius: 15px;
  width: 300px;
  height: 30px;
  display: flex;
  align-items: center;
  z-index: 2;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: #ff324d;
  color: white;
  font-size: 12px;
}
.support-bt:hover {
  color: white;
}
@media only screen and (max-width: 1050px) {
  .support-bt {
    cursor: pointer;
    border: none;
    left: 22px;
    padding: 10px, 10px;

    border-radius: 15px;
    width: 150px;

    height: 30px;
    display: flex;
    align-items: center;
    z-index: 2;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    white-space: nowrap;
    background-color: #ff324d;
    color: white;
    font-size: 0.5rem;
  }
}

.icon-container {
  position: relative;
  display: inline-block;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
  margin-right: 20px;
}

.icon-container:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  background-color: #dd112c1c; /* Adjust as needed */
  border-radius: 50%;
  z-index: -1;
}

.number {
  position: absolute;
  top: -8px; /* Adjust as needed */
  right: -8px; /* Adjust as needed */
  background-color: red; /* Adjust as needed */
  color: #fff; /* Adjust as needed */
  width: 20px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  border-radius: 50%;
  font-size: 12px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.discount {
  background-color: rgba(255, 0, 0, 0.7);
  position: absolute;
  right: 2px;
  top: 1px;
  text-transform: uppercase;
  color: #fff;
  padding: 2px 8px;
  font-size: 13px;
  width: 100%;
  z-index: 1;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.productId {
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
.input-group .withoutborder {
  border-right: none;
  box-shadow: none;
}

.noleft {
  border-left: none;
}

.callout.callout {
  --background: #f8f8f9;
  --border: #8b939c;
  background: var(--background);
  border-color: var(--border);
  color: var(--text);
  padding: 1.33rem;
}
.callout.callout_info {
  --background: #e3edf2;
  --title: #46b8da;
  --border: #5bc0de;
}

.changebg {
  transition: transform 0.3s ease;
}
.changebg :hover {
  background-color: #dad2eb;
  cursor: pointer;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: 700 !important;
  justify-content: center !important;
}
.css-78d1bb .MuiDataGrid-columnHeaderTitleContainer {
  font-weight: 700 !important;
  justify-content: center !important;
}
.MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  overflow: hidden;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.css-5wly58-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}
.css-5wly58-MuiDataGrid-root {
  border-style: hidden !important;
  box-shadow: none !important;
  font-family: "Inter", "", "sans-serif" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
.css-919eu4 {
  background-color: transparent !important;
}
.pr_flash {
  background-color: #ff9f00;
  position: absolute;
  right: 0px;
  top: 0px;
  text-transform: uppercase;
  color: #fff;
  padding: 2px 8px;
  font-size: 13px;
  z-index: 1;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #594df1 !important;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
}
.card-item img {
  width: "194px";
  height: "200px";
  border-radius: "22px";
}
.card-item {
  flex: 1 1 calc(20% - 16px);
  max-width: calc(21% - 16px);
  box-sizing: border-box;
}

.card-container.fewer-than-five .card-item {
  max-width: none;
}
.card {
  width: 100%; /* Ensure card takes full width of its container */
}

@media (max-width: 1100px) {
  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    justify-content: space-evenly;
  }
  .card-item {
    padding-left: 0px;
    flex: 1 1 calc(25% - 16px); /* 4 cards per row */
    max-width: calc(20% - 16px);
  }
}

@media (max-width: 992px) {
  .card-item {
    flex: 1 1 calc(33.33% - 16px); /* 3 cards per row */
    max-width: calc(33.33% - 16px);
  }
}

@media (max-width: 768px) {
  .card-item {
    flex: 1 1 calc(50% - 16px); /* 2 cards per row */
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 576px) {
  .card-item {
    flex: 1 1 calc(100% - 16px); /* 1 card per row */
    max-width: calc(55% - 16px);
  }
  .card-container {
    gap: 5px;
  }
}

.card-img-top {
  width: 100%;
  height: auto;
  border-radius: 22px;
}
.css-rmmij8 {
  margin-bottom: 14px;
}

.scroll-hidden-x {
  overflow-x: scroll; /* Allows horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrollbar if needed */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-hidden-x::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}
.col-md-15-custom {
  width: 100%; /* Fallback for non-grid systems */
  flex: 0 0 62.5%; /* Calculate percentage: (15/24)*100 */
  max-width: 62.5%; /* Same calculation for max-width */
}

@media (max-width: 768px) {
  /* Or any other breakpoint you prefer */
  .col-md-15-custom {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* carousel */
/* 
.slick-arrow.slick-next,
.slick-arrow.slick-prev{
background-color: #428cff;
height:25px;
width: 25px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.8;
height:30px;
width:30px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    background-color: #2574f5;
    opacity:1;
}


.slick-prev::before,.slick-next::before{
   display:none;
    
    }

.arrows{
height:20px;
width:20px;
margin: 1px;

} */

/* Styles for the custom carousel container */
.custom-carousel {
  display: flex;
  overflow: hidden;
  justify-content: center;
  position: relative;
  width: 100%;
}

.carousel-inner {
  display: flex;
  justify-content: space-evenly;
  gap: 25px;
  width: 100%;

  /* Adjust the duration and easing as needed */
}
.carousel-inner div {
  transition: transform 0.5s ease-in-out;
  width: fit-content;
  margin: auto;
}

.custom-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.custom-arrow.prev {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-arrow.next {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-padding {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .custom-padding {
    padding-top: 190px;
  }
}

@media (max-width: 768px) {
  .support-bg {
    display: none;
  }
}
